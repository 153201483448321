import  React, { useState } from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"
import Variables from "../../../components/variables"


const Olorinab = () => {

	
	const [isChecked0, setIsChecked0] = useState(false); 
const [isChecked1, setIsChecked1] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const [isChecked3, setIsChecked3] = useState(false);
const [isChecked4, setIsChecked4] = useState(false);
const [isChecked5, setIsChecked5] = useState(true);
const [isChecked6, setIsChecked6] = useState(false);
const [isChecked7, setIsChecked7] = useState(false);
const [isChecked8, setIsChecked8] = useState(false);
const [isChecked9, setIsChecked9] = useState(false);
const [isChecked10, setIsChecked10] = useState(false);
const [isChecked11, setIsChecked11] = useState(false);	

const handleOnChange0 = () => {
	//select all
	
	if(isChecked0 == false)
		{
		
	   		setIsChecked1(false)
			setIsChecked2(false)
	   		setIsChecked3(false)
	   		setIsChecked4(false)
			setIsChecked5(false)
	   		setIsChecked6(false)
	   		setIsChecked7(false)
			setIsChecked8(false)
	   		setIsChecked9(false)
	   		setIsChecked10(false)
		}
	
	  setIsChecked0(!isChecked0);
	 
};

const handleOnChange1 = () => {
    //etrasimod all
	 if(isChecked1 == false)
	 {
	   setIsChecked0(false)
	   setIsChecked2(true)
	   setIsChecked3(true)
	   setIsChecked4(true)
	 }
	else{
	
	   setIsChecked2(false)
	   setIsChecked3(false)
	   setIsChecked4(false)
	 }
	
	 setIsChecked1(!isChecked1);
 };

  const handleOnChange2 = () => {
 
	if(isChecked2 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked1 == false)
	 {setIsChecked1(false)}
	  
	   setIsChecked2(!isChecked2); 
 };

  const handleOnChange3 = () => {
	
	  if(isChecked3 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked2 == false)
	 {setIsChecked0(false)}
	  
    setIsChecked3(!isChecked3);
};
	
const handleOnChange4 = () => {
	
	  if(isChecked4 == false)
		{
			setIsChecked0(false)
		}
	  
	  
    setIsChecked4(!isChecked4);
};
	

  const handleOnChange5 = () => {
	//olorinab all
	  
	   if(isChecked5 == false)
	 {setIsChecked0(false)
	   setIsChecked6(true)
	   setIsChecked7(true)
	   setIsChecked8(true)
	 }
	else{
		
	   setIsChecked6(false)
	   setIsChecked7(false)
	   setIsChecked8(false)
	 }
	  
    setIsChecked5(!isChecked5);
};


  const handleOnChange6 = () => {
	  if(isChecked6 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
	  
    setIsChecked6(!isChecked6);
 };

  const handleOnChange7 = () => {
	  if(isChecked7 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked7(!isChecked7);
};

  const handleOnChange8 = () => {
	  if(isChecked8 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked8(!isChecked8);
 };

  const handleOnChange9 = () => {
	  if(isChecked9 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked9(!isChecked9);
};

  const handleOnChange10 = () => {
	  if(isChecked10 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked10(!isChecked10);
};
	
const handleOnChange11 = () => {
	if(isChecked11 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked11(!isChecked11);
};

	
function renderFilteredData()
	{
		var checkboxStates = [
			[isChecked0, ""],
			[isChecked1, "etrasimod"],
			[isChecked2, "etrasimod-preclinical"],
		    [isChecked3, "etrasimod-phase1"],
			[isChecked4, "etrasimod-phase2"],
			[isChecked5, "olorinab"],
			[isChecked6, "olorinab-preclinical"],
			[isChecked7, "olorinab-phase1"],
			[isChecked8, "olorinab-phase2b"],
			[isChecked9, "sphingosine"],
			[isChecked10, "thromboembolic-events"],
			[isChecked11, "ulcerative-colitis"]
			
		];
		var arrayToCheck = [];
		
		
		for(var i = 0; i<checkboxStates.length; i++ )
		{
			
			if(checkboxStates[i][0]== true){ arrayToCheck.push(checkboxStates[i][1])}	
			//alert(checkboxStates[i][0]);
		}
		if(arrayToCheck.length == 0){ return "";}
		
		//if(checkboxStates[0][0]== true){ arrayToCheck.push(checkboxStates[0][1])}
		//alert(checkboxStates.length);
	return	Variables.keyTopic.filter(item => (  checkStringWithArray(item.filters, arrayToCheck))   ).map((item) =>							   
<a key={item.key} href={item.link} target="_blank" rel="noreferrer">
<>{item.title}</><>{item.label} 
</><div><hr /><img src={"/images/view-button.svg"} alt="View" /></div></a>
					 );

	}
	
	
function checkStringWithArray(theString, theArray)
	{
	if(theString == null) return;
		
		

		for(var i=0; i<theArray.length; i++)
			{
				if( theString.indexOf(theArray[i]) != -1 )
					{ return true;}
			}
			
		return false;
	}

	
return (
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
	    
	    <script src={'/js/filter.js'} type="text/javascript" />
    </Helmet>
    <Seo title="Olorinab" description="Olorinab is under investigation for the management of visceral pain in irritable bowel syndrome. Read about the Phase 2 clinical trial and view key references" />
    <header className="headline">
	<img src={"/images/our-pipeline-gastroenterology.svg"} alt="Our pipeline gastroenterology" />
	</header>
	<section className="wrap"><h2>We’re pursuing every opportunity to develop and bring our investigational drugs <nobr>to patients</nobr></h2></section>
	<br />
	<section className="wrap center narrow">
	<h1 className="drugName">OLORINAB</h1>
	<p className="no-top"><strong>A drug candidate for the treatment of IBS pain</strong></p>
		<p><img src={"/images/subhead-divider.svg"} /></p>
		<p className="product"><strong>Olorinab</strong> is an oral, peripherally acting, nonopioid analgesic under investigation for the management of visceral pain in irritable bowel syndrome. Olorinab is being evaluated to determine its safety and whether it may have sustained efficacy due to full cannabinoid type 2 (CB<sub>2</sub>) agonist activity, with a low risk of psychoactive effects due to high selectivity for CB<sub>2</sub> versus cannabinoid type 1.</p>
<p>
Olorinab is an investigational drug and is not currently approved for use by any health authority.
This information is not intended to promote or recommend olorinab for any use.</p>

	</section>
	
	<section className="wrap back-to-top"><header className="orange rule" id="olo-clinical"><h1>Olorinab <span>clinical trial program</span></h1></header>
		<div className="pipeline-row">
		  <div className="phase"><strong>PHASE 2</strong><span>COMPLETE</span></div><div className="info"><header className="logoSection"><img src={"/images/captivate-trial-graphic-image.svg"} alt="captivate trial" /></header><p><strong>CAPTIVATE</strong> is a Phase 2 clinical trial evaluating the efficacy and safety of oral olorinab in patients experiencing abdominal pain associated with irritable bowel syndrome (IBS).</p>
			<div className="link"><a href="https://clinicaltrials.gov/ct2/show/NCT04043455" target="_blank"><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT04043455</div><span className="legal">Olorinab is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend olorinab for any use.</span></div></div>
		
		
	</section>
	<section className="spacer"></section>
	  <section className="wrap"><header className="orange rule"><h1>Olorinab <span>key references</span></h1></header>
	   <div className="scrollBox"><div className="filter-area"><img src={"/images/filter-open.svg"} className="mobileFilterBtn"/>
	<div className="filter-controls publications">
			  
	
	<label className="container" ><strong>Select All</strong>
	<input type="checkbox"  checked={isChecked5} onChange={handleOnChange5} name="OlorinabAll" value="OlorinabAll" id="OlorinabdAll"  />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Preclinical Studies
 			  <input type="checkbox"   checked={isChecked6}  onChange={handleOnChange6} name="OlorinabPreclinical" value="OlorinabPreclinical" id="OlorinabPreclinical" />
				  <span className="checkmark"></span>
			  </label>
	
			  <label className="container" >Phase 1 Studies
 			  <input type="checkbox" name="Phase1" value="OlorinabPhase1" id="OlorinabPhase1" checked={isChecked7} onChange={handleOnChange7}/>
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Phase 2a Study
 			  <input type="checkbox"  checked={isChecked8}  onChange={handleOnChange8}/>
				  <span className="checkmark"  name="OlorinabPhase2" value="OlorinabPhase2" id="OlorinabPhase2"></span>
			  </label>
	
			  
			  
		  </div>
		  <div className="filter-results publications"> 
	{renderFilteredData()}
	

		  </div></div>
	    </div>
	</section>
	 <section className="spacer end"></section>
	<footer className="definitions">IBD=inflammatory bowel disease.<br/><span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span>
</footer>
  </Layout>
)};

export default Olorinab
